import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import logo from '../img/logo.svg';



class Nav extends Component {
  state = {
    //creo el objeto para cargar el fetch
    items: []
  }

  //cargo los metodos de carga de datos
  componentDidMount() {
    this.consultarMenu();

  }

  //llamar a la api para traer portfolio
  consultarMenu = async () => {
    //cargo la url
    const url = 'https://admin.tucodigital.com/wp-json/api/v1/menu_principal';
    //hago un fetch de la noticia
    const respuesta = await fetch(url);
    //retornamos la respuesta en un json (para procesarla)
    const items = await respuesta.json();
    //envio la respuesta al state noticias
    this.setState({
      //llamo solo a los articulos, por que no uso el resto de la API (response y demas)
      items: items
    })
  }


  render() {
    return (
      <Fragment>
        <nav className="navbar navbar-expand-lg navbar-dark bg-dark shadow fixed-top">
          <Link className="navbar-brand" to={'/'}><img alt="logo Tuco Digital" src={logo} height="39px" /></Link>
          <button className="navbar-toggler btn-danger shadow p-3" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
            aria-label="Toggle navigation">
            <i className="fas fa-bars text-white"></i>
          </button>

          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ml-auto">
              {this.state.items.map((item) => {

                if (item.titulo !== "Portfolio") {
                  return (
                    <li className="nav-item mx-2" key={item.id}>
                      <Link className="nav-link" to={item.url}>{item.titulo}</Link>
                    </li>
                  );
                } else {
                  return (
                    <li className="nav-item mx-2" key={item.id}>
                      <Link className="nav-link btn btn-danger px-4" to={item.url}>{item.titulo}</Link>
                    </li>
                  );
                }
              }
              )}
            </ul>
          </div>
        </nav>

        <div className="social">
          <div id="social" className="btn-group dropup atencion">
            <button type="button" className="btn btn-danger btn-circle shadow dropdown-toggle" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i className="fas fa-comment-alt"></i>
            </button>
            <div className="dropdown-menu p-0 bg-transparent border-0 mb-0 mr-3">
              <div className="text-right">
                <ul className="social-l">
                  <li className="mb-2">
                    <a href="https://www.instagram.com/tucodigital"
                      className="btn btn-dark btn-circle shadow"><i className="fab fa-instagram"></i></a>
                  </li>
                  <li className="mb-2">
                    <a href="http://m.me/tucodigital" className="btn btn-dark btn-circle shadow"><i
                      className="fab fa-facebook-messenger"></i></a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Nav;


