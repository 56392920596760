import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Loading from './Loading';
import Footer from '../components/Footer';
import Lottie from 'react-lottie';
import MainAnim from '../assets/anim/main.json';
import PlanAnim from '../assets/anim/plan.json';
import ScrollAnimation from 'react-animate-on-scroll';

class Home extends Component {
  state = {
    //creo el objeto para cargar el fetch
    inicio: [],
    loading: true,
    mainOpciones: {
      loop: true,
      autoplay: true,
      animationData: MainAnim,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    },
    planOpciones: {
      loop: true,
      autoplay: true,
      animationData: PlanAnim,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }
  }

  //cargo los metodos de carga de datos
  componentDidMount() {
    this.consultarInicio();
  }

  //llamar a la api para traer index
  consultarInicio = async () => {
    //cargo la url
    const url = 'https://admin.tucodigital.com/wp-json/api/v1/inicio';
    //hago un fetch de la noticia
    const respuesta = await fetch(url);
    //retornamos la respuesta en un json (para procesarla)
    const inicio = await respuesta.json();
    //envio la respuesta al state noticias

    this.setState({
      //llamo solo a los articulos, por que no uso el resto de la API (response y demas)
      inicio: inicio,
      loading: false,
    })
  }

  render() {

    if (this.state.loading) {
      return (
        <Fragment>
          <Loading />
        </Fragment>
      );

    } else {

      return (

        <Fragment>

          <section id="home" className="p-home">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-lg-6 mb-4">
                  <h1 className="display-4">{this.state.inicio.titulo_principal}</h1>
                  <p className="lead">
                    {this.state.inicio.subtitulo_principal}
                  </p>
                  <h4><i className="fas fa-map-marker-alt mr-2 text-danger"></i> La Plata, Buenos Aires.</h4>
                </div>
                <div className="col-lg-6 mb-4">
                  <div className="img-main">
                    <Lottie options={this.state.mainOpciones} />
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="p-mid">
            <div className="container-fluid p-0">
              <div className="row">

                <div className="col-lg-4 mb-5">
                  <ScrollAnimation animateIn='bounceInRight' animateOnce={true} delay={100} duration={2}>
                    <div className="row">
                      <div className="col-1">
                        <h1 className="text-danger"><i className="fas fa-pencil-ruler"></i></h1>
                      </div>
                      <div className="col ml-3">
                        <h4>{this.state.inicio.item_1}</h4>
                        <div className="border-box my-3"></div>
                      </div>
                    </div>
                    <p className="lead">
                      {this.state.inicio.item_1_texto}
                    </p>
                  </ScrollAnimation>
                </div>

                <div className="col-lg-4 mb-5">
                  <ScrollAnimation animateIn='bounceInRight' animateOnce={true} delay={200} duration={2}>
                    <div className="row">
                      <div className="col-1">
                        <h1 className="text-danger"><i className="fas fa-bullhorn"></i></h1>
                      </div>
                      <div className="col ml-3">
                        <h4>{this.state.inicio.item_2}</h4>
                        <div className="border-box my-3"></div>
                      </div>
                    </div>
                    <p className="lead">
                      {this.state.inicio.item_2_texto}
                    </p>
                  </ScrollAnimation>
                </div>

                <div className="col-lg-4 mb-5">
                  <ScrollAnimation animateIn='bounceInRight' animateOnce={true} delay={300} duration={2}>
                    <div className="row">
                      <div className="col-1">
                        <h1 className="text-danger"><i className="fas fa-tools"></i></h1>
                      </div>
                      <div className="col ml-3">
                        <h4>{this.state.inicio.item_3}</h4>
                        <div className="border-box my-3"></div>
                      </div>
                    </div>
                    <p className="lead">
                      {this.state.inicio.item_3_texto}
                    </p>
                  </ScrollAnimation>
                </div>


                <div className="col-lg-6 align-self-center">

                  <ScrollAnimation animateIn='bounceInLeft' animateOnce={true} delay={100} duration={2}>
                  <div className="row">
                      <div className="col-1">
                        <h1 className="text-danger"><i className="fas fa-layer-group"></i></h1>
                      </div>
                      <div className="col ml-3">
                        <h4>Planificación</h4>
                        <div className="border-box my-3"></div>
                      </div>
                    </div>
                    <p className="lead">
                      {this.state.inicio.planificacion}
                    </p>
                  </ScrollAnimation>

                </div>

                <div className="col-lg-6 ">

                  <ScrollAnimation animateIn='bounceInRight' animateOnce={true} duration={2}>
                    <Lottie options={this.state.planOpciones} />
                  </ScrollAnimation>

                </div>

              </div>
            </div>
          </section>

          <section id="last" className="p-last">
            <div className="container-fluid p-0">

              <ScrollAnimation animateIn='bounceInUp' animateOnce={true} duration={2}>
                <div className="bg-pattern p-5">
                  <div className="row no-gutters">
                    <div className="col-lg-12 text-center">
                      <h1 className="mb-4">Trabajemos Juntos</h1>
                      <Link to={'/contacto'} className="btn btn-dark btn-lg px-5 shadow"><i className="far fa-paper-plane mr-2 text-danger"></i> Escribinos</Link>
                    </div>
                  </div>
                </div>
              </ScrollAnimation>

            </div>
          </section>

          <Footer />
        </Fragment>
      );
    }
  }
}

export default Home;
