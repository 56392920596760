import React, { Component, Fragment } from 'react';
import Loading from '../components/Loading';
import { Link } from 'react-router-dom';
import Footer from '../components/Footer';

var parse = require('html-react-parser');

class Item extends Component {
    state = {
        //creo el objeto para cargar el fetch
        items: [],
        loading: true
    }

    //cargo los metodos de carga de datos
    componentDidMount() {
        this.consultarMenu();
    }

    //llamar a la api para traer portfolio
    consultarMenu = async () => {
        //cargo la url
        const itemUrl = this.props.match.params.slug;
        const url = `https://admin.tucodigital.com/wp-json/api/v1/item_portfolio/${itemUrl}`;
        //hago un fetch de la noticia
        const respuesta = await fetch(url);
        //retornamos la respuesta en un json (para procesarla)
        const items = await respuesta.json();

        //envio la respuesta al state noticias
        this.setState({
            //llamo solo a los articulos, por que no uso el resto de la API (response y demas)
            items: items,
            loading: false

        })
    }


    render() {

        if (this.state.loading) {
            return (
                <Fragment>
                    <Loading />
                </Fragment>
            );

        } else {

            return (
                <Fragment>
                    <section className="p-cont">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-lg-4 mb-3">
                                    <img className="img-portfolio rounded mb-4"
                                        src={this.state.items.img_portada} alt={this.state.items.titulo} />
                                </div>
                                <div className="col-lg-8 mb-3">
                                    <h1>{this.state.items.titulo}</h1>
                                    <p className="lead"><i className="fas fa-map-marker-alt mr-2 text-danger"></i> {this.state.items.localidad}</p>
                                    <p>
                                        {parse(`${this.state.items.contenido}`)}
                                    </p>

                                    <div className="row no-gutters">
                                        {this.state.items.rubro ?
                                            <div className="col-lg-3 mb-3">
                                                <h4>Rubro</h4>
                                                <p>{this.state.items.rubro}</p>
                                            </div>
                                            : null
                                        }
                                        {this.state.items.color_1 ?
                                            <div className="col-lg-3  mb-3">
                                                <h4>Colores</h4>
                                                <div className="row">
                                                    <div className="col-12 d-flex align-items-center mb-2">
                                                        <div className="color" style={{ backgroundColor: this.state.items.color_1 }}></div>
                                                        <div className="text-white ml-2">{this.state.items.color_1}</div>
                                                    </div>
                                                    <div className="col-12 d-flex align-items-center mb-2">
                                                        <div className="color" style={{ backgroundColor: this.state.items.color_2 }}></div>
                                                        <div className="text-white ml-2">{this.state.items.color_2}</div>
                                                    </div>
                                                    <div className="col-12 d-flex align-items-center mb-2">
                                                        <div className="color" style={{ backgroundColor: this.state.items.color_3 }}></div>
                                                        <div className="text-white ml-2">{this.state.items.color_3}</div>
                                                    </div>
                                                </div>
                                            </div>
                                            : null
                                        }
                                        {this.state.items.imagen_1 ?
                                            <div className="col mb-3">
                                                <h4>Galería</h4>
                                                <div className="row">
                                                    <div className="col-lg-4 d-flex justify-content-center mb-2">
                                                        <a data-fancybox="gallery" href={this.state.items.imagen_1}>
                                                            <img className="rounded img-thumb shadow" src={this.state.items.img_1_thumb} alt={this.state.items.titulo} />
                                                        </a>
                                                    </div>
                                                    {this.state.items.imagen_2 ?
                                                        <div className="col-lg-4 d-flex justify-content-center mb-2">
                                                            <a data-fancybox="gallery" href={this.state.items.imagen_2}>
                                                                <img className="rounded img-thumb shadow" src={this.state.items.img_2_thumb} alt={this.state.items.titulo} />
                                                            </a>
                                                        </div>
                                                        : null
                                                    }
                                                    {this.state.items.imagen_3 ?
                                                        <div className="col-lg-4 d-flex justify-content-center mb-2">
                                                            <a data-fancybox="gallery" href={this.state.items.imagen_3}>
                                                                <img className="rounded img-thumb shadow" src={this.state.items.img_3_thumb} alt={this.state.items.titulo} />
                                                            </a>
                                                        </div>
                                                        :null
                                                    }
                                                </div>
                                            </div>
                                            : null
                                        }
                                    </div>
                                </div>
                                    
                                <div className="col-lg-12 text-center mt-4">
                                    <Link className="btn btn-danger px-5" to={'/portfolio'}>Volver</Link>
                                </div>
                            </div>
                        </div>
                    </section>

                    <Footer />
                </Fragment >
            );
        }

    }
}

export default Item;




