import React from 'react';
import Lottie from 'react-lottie';
import LoadingAnim from '../assets/anim/loading.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: LoadingAnim,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const Loading = () => (

    <section id="loading" className="bg-dark">
        <div className="text-center p-loading">
            <Lottie options={defaultOptions} height={130} width={130} />
            <h5 className="carg">cargando<span className="text-danger"> ...</span></h5>
        </div>
            
    </section>

)

export default Loading;