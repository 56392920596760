import React, { Component, Fragment } from 'react';

import Items from '../components/Items';
import Paginador from '../components/Paginador';
import Loading from '../components/Loading';
import Footer from '../components/Footer';


class Portfolio extends Component {
  state = {
    //creo el objeto para cargar el fetch
    items: [],
    loading: true,
    paginas: [],
    page: 1
  }

  //cargo los metodos de carga de datos
  componentDidMount() {
    this.consultarItems(1);
  }

  consultarPaginas = (e) => {
    this.consultarItems(e.target.value);
  }

  //llamar a la api para traer portfolio
  consultarItems = async (page) => {

    this.setState({
      loading: true
    })

    //cargo la url
    const url = `https://admin.tucodigital.com/wp-json/api/v1/items/${page}`;
    //hago un fetch de la noticia
    const respuesta = await fetch(url);
    //retornamos la respuesta en un json (para procesarla)
    const items = await respuesta.json();

    //traigo el total de páginas que hay en la query
    const totalPaginas = items.total_paginas;

    //guardo las paginas en un array y las recorro 1 por 1, para mostrarlas despues.
    const pag = [];
    for (var i = 1; i <= totalPaginas; i++) {
      pag.push(i);
    }

    //envio la respuesta al state
    this.setState({
      //llamo solo a los articulos, por que no uso el resto de la API (response y demas)
      items: items.post,
      loading: false,
      paginas: pag,
      page: parseInt(page)
    })

  }

  render() {
    if (this.state.loading) {
      return (
        <Fragment>
          <Loading />
        </Fragment>
      );

    } else {
      return (
        <Fragment>
          <section className="p-cont">
            <div className="container-fluid p-0">
              <div className="row">
                <Items items={this.state.items} loading={this.state.loading} />
                <div className="col-lg-12 mt-5">
                  <Paginador paginas={this.state.paginas} page={this.state.page} consultarPaginas={this.consultarPaginas} />
                </div>
              </div>
            </div>
          </section>
          <Footer />
        </Fragment>
      );
    }
  }
}

export default Portfolio;


