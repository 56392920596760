import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import Home from './components/Home';
import Portfolio from './components/Portfolio';
import Contacto from './components/Contacto';
import Item from './components/Item';
import Nav from './components/Nav';
import './index.css';
import ScrollToTop from './components/ScrollToTop';

const App = () => (
    <BrowserRouter>
        <Nav/>
        <ScrollToTop />
        <Switch>
            <Route path="/item/:slug" component={Item} />
            <Route path="/portfolio" exact component={Portfolio} />
            <Route path="/contacto" exact component={Contacto} />
            <Route path="/" exact component={Home} />
            <Route path="*" exact component={() => <Redirect to="/" />} />
        </Switch>
    </BrowserRouter>
)

ReactDOM.render(<App />, document.getElementById('root'));

