import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import LoadingApp from '../components/LoadingApp'

class Items extends Component {

    render() {
        if (this.props.loading) {
            return (
                <LoadingApp />
            );
        } else {
            return (
                this.props.items.map((item) => {
                    return (
                        <div className="col-lg-4 mb-4" key={item.id}>
                            <img className="img-portfolio rounded mb-4" src={item.img_portada} alt={item.titulo} />
                            <div className="row">
                                <div className="col-lg-8 mb-3">
                                    <h4>{item.titulo}</h4>
                                    <p className="m-0">
                                        <i className="fas fa-map-marker-alt mr-2 text-danger"></i>
                                        {item.localidad}
                                    </p>
                                </div>
                                <div className="col-lg-4 align-self-center">
                                    <Link className="btn btn-block btn-danger" to={`/item/${item.slug}`}>Ver Trabajo</Link>
                                </div>
                            </div>
                        </div>
                    );
                })
            );

        }

    }
}

export default Items;





