import React, {Component}  from 'react';

    class Paginador extends Component {

        render(){
          const {consultarPaginas, paginas, page} = this.props;
          return(
            <div className="col-lg-12 d-flex justify-content-center">
                <nav aria-label="Page navigation" role="navigation">
                    <ul className="pagination">
                    {
                        paginas.map((p) => {  
                            if(page === p){
                                return(
                                    <li className="page-item active"  key={p}>
                                        <button className="page-link" value={p} onClick={e => consultarPaginas(e, p)} >{p}</button>
                                    </li>
                                );   
                            }else{
                                return(
                                    <li className="page-item "  key={p}>
                                        <button className="page-link" value={p} onClick={e => consultarPaginas(e, p)} >{p}</button>
                                    </li>
                                );

                            }

                        })
                    }
                    </ul>
                </nav>   
            </div>
          );
        }
      }
    
export default Paginador;


