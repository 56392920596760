import React, { Component, Fragment } from 'react';
import Lottie from 'react-lottie';
import ContAnim from '../assets/anim/contacto.json';
import Footer from './Footer';

class Contacto extends Component {

    state = {
        
        contOpciones: {
            loop: true,
            autoplay: true,
            animationData: ContAnim,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid slice'
            }
        }
    }

    uploadAction = async (e) => {
        e.preventDefault();

        if (!e.target.checkValidity()) {
            // el form es invalido
            this.setState({ mostrarError: true, valido: false });
            return;
        }

        // el form es valido
        this.setState({ mostrarError: false, cargando: true });
        var data = new FormData(e.target);

        const options = {
            method: "POST",
            body: data
        }

        const request = new Request("https://admin.tucodigital.com/wp-json/contact-form-7/v1/contact-forms/5/feedback", options);
        const response = await fetch(request);
        const status = response.status;
        const mensaje = await response.json();



        if (status === 200) {
            this.setState({
                valido: true,
                mensaje: mensaje.message,
                cargando: false
            });

            this.form.reset();

        }

        if (status === 404) {
            this.setState({
                noValido: true,
                mensaje: mensaje.message,
                cargando: false
            });
        }

        if (status === 400) {
            this.setState({
                noValido: true,
                mensaje: mensaje.message,
                cargando: false
            });
        }

    }

    render() {
        const { mostrarError, valido, noValido, cargando } = this.state;
        return (
            <Fragment>
                <section className="p-cont bg-pattern">
                    <div className="container-fluid p-0">
                        <div className="row">
                            <div className="col-lg-4 mb-4 align-self-center">
                                <Lottie options={this.state.contOpciones} />
                            </div>
                            <div className="col-lg-8 align-self-center mb-4">
                                <h4>Trabajemos Juntos</h4>
                                <div className="border-box bg-dark my-3"></div>
                                <form noValidate ref={form => this.form = form} className={mostrarError ? 'form-row error' : 'form-row valid'} encType="multipart/form-data" onSubmit={this.uploadAction}>
                                    <div className="col-lg-6 mb-3">
                                        <div className="validado">
                                            <i className="fas fa-check text-success"></i>
                                        </div>
                                        <input
                                            type="text"
                                            name="nombre"
                                            className="form-control rounded shadow"
                                            placeholder="Nombre"
                                            defaultValue=""
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <input
                                            type="text"
                                            name="apellido"
                                            className="form-control rounded shadow"
                                            placeholder="Apellido"
                                            defaultValue=""
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-6 mb-3">
                                        <input
                                            type="email"
                                            name="mail"
                                            className="form-control rounded shadow"
                                            placeholder="Mail"
                                            defaultValue=""
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-2 mb-3">
                                        <input
                                            type="number"
                                            name="area"
                                            className="form-control rounded shadow"
                                            placeholder="ej. 0221"
                                            defaultValue=""
                                            min="0"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-4 mb-3">
                                        <input
                                            type="number"
                                            name="tel"
                                            className="form-control rounded sshadow"
                                            placeholder="Teléfono"
                                            defaultValue=""
                                            min="0"
                                            required
                                        />
                                    </div>
                                    <div className="col-lg-12 mb-3">
                                        <textarea
                                            rows="3"
                                            name="mensaje"
                                            defaultValue=""
                                            className="form-control rounded-textarea shadow"
                                            placeholder="Escribinos tu mensaje"
                                            required
                                        >
                                        </textarea>
                                    </div>
                                    <div className="col-lg-12 text-center mb-3">
                                        <div className={mostrarError ? 'error-text mostrar bg-error p-2 shadow rounded mb-3' : 'error-text no-mostrar'} >
                                            <p className="text-white m-0"><i className="fas fa-bolt text-warning"></i> ¡Error! Parece que algunos campos son invalidos.</p>
                                        </div>
                                        <div className={valido ? 'error-text mostrar bg-success p-2 shadow rounded mb-3' : 'error-text no-mostrar'} >
                                            <p className="text-white m-0"><i className="fas fa-heart text-danger"></i> {this.state.mensaje}</p>
                                        </div>
                                        <div className={noValido ? 'error-text mostrar bg-error p-2 shadow rounded mb-3' : 'error-text no-mostrar'} >
                                            <p className="text-white m-0"><i className="fas fa-bolt text-warning"></i> {this.state.mensaje}</p>
                                        </div>
                                        <button type="submit" className="btn btn-dark btn-lg rounded px-5 shadow">
                                            <span className={cargando ? 'spinner-border text-light mr-3' : ''} role="status" aria-hidden="true"></span>
                                            Enviar
                                    </button>
                                    </div>
                                </form >

                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </Fragment>
        )
    }


}

export default Contacto;

