import React from 'react';

const Footer = () =>(

<footer className="bg-dark">
        <div className="p-4 text-center">
            <h5 className="m-0">Tuco Digital @2019</h5>
            <p>Creada en <i className="text-info fab fa-react"></i>
                React con <i className="fas fa-heart text-danger"></i>
            </p>
            <p className="small m-0">La Plata, Buenos Aires</p>
            <p className="small">Diseño y Desarrollo: Tuco Digital</p>
        </div>
    </footer>

)

export default Footer;